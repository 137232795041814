import React from "react";
import {Layout} from "../Layout";
import ProductBanner from "../product_banners/product_banners";
import Banner from "../../imgs/bg_buniversity.jpg";

const BalanzUniversityLayout = ({pageData, children}) => {
    const page = (
        <>
            <ProductBanner
                headingType="h1"
                bannerContainerClassName="bannerUniversity"
                bannerTrasparecy="bannerMask"
                productTitle="Balanz University"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={null}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />

            {children}

        </>
    )
    return (
        <main>
            <Layout title={pageData.title} description={pageData.description} childrem={page}
                    category={"balanz_university"}></Layout>
        </main>
    )
}


export default BalanzUniversityLayout;