import { graphql } from "gatsby";
import React from "react";
import BalanzUniversityLayout from "../../components/balanz-university/BalanzUniveristyLayout";
import BalanzUniversity from "../../components/balanz-university/BalanzUniversity";

const pageData = {
    title: 'Todo sobre Educación Financiera | Balanz University',
    description: 'En Balanz trabajamos para impulsar la educación financiera, contribuyendo a la difusión del conocimiento y convirtiendola en una posibilidad para todos.',
    bannerText: `El mejor lugar <span class="highlightsbold">para aprender a invertir.</span>`
};

const BalanzUniversityPage = ({data}) => {
    return <BalanzUniversityLayout pageData={pageData}>
        <BalanzUniversity artdata={data.allUniversityArticles.edges} />
    </BalanzUniversityLayout>
}

export const query = graphql`
    query {
        allUniversityArticles {
            edges {
                node {
                    slug
                    title
                    description
                    category
                    date
                    timestamp
                    imgUrl
                    highlighted
                    artid
                    visits
                    order
                    metatitle
                    htmldesc
                    section
                    htmlcontent
                }
            }
        }
    }
`
export default BalanzUniversityPage